@import "../node_modules/bootstrap/scss/bootstrap.scss";
@import "../node_modules/@wellmark/wm-lib-ux/assets/wellmark/wm-style";

@import "../node_modules/font-awesome/css/font-awesome.css";

@import "./assets/styles/panels.scss";

.container {
    margin-top: 36px;
}

a.link-condensed {
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
}

.link-icon {
    white-space: normal !important;
    text-decoration: none;
    white-space: nowrap;
}

.link-icon .link-text {
    text-decoration: underline;
    vertical-align: middle;
}

strong {
    font-weight: 700;
}