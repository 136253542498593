// @import "../../../node_modules/bootstrap/less/panels.less";
@import "../../../node_modules/@wellmark/wm-lib-ux/assets/wellmark/mxm-mixins";
@import "../../../node_modules/@wellmark/wm-lib-ux/assets/wellmark/mxm-variables";
@import "./panel-variables.scss";
@import "./bootstrap-panel.scss";

.panel {
	margin-bottom: 0;
	@include border-radius(6px);
  &.pad-sm {
		.panel-body {
			padding: 15px;
		}
	}
}

.panel-heading {
	font-family: $font-family-sans-serif;
	font-weight: 700;
	font-size: 18px;
	line-height: 1.1;
	text-transform: uppercase;
	text-align:center;
	position: relative;
	border-top-left-radius: 6px;
	border-top-right-radius: 6px;
	min-height: 61px;
	display: table;
	width: 100%;

	.panel-heading-inner {
		display: table-cell;
		width: 100%;
		padding: 0 5px;
		vertical-align: middle;
	}

	.panel-heading-title {
		margin: 0;
		font-size: 18px;
		line-height: 1.1;
		font-weight: 700;
  }

  @media (min-width: $screen-md-min) {
    .panel-heading-inner {
      padding: 0 15px;
    }
  }

}

.panel-body {
	padding: 20px;

	> .amount,
	.percentage {
		text-align: center;
		font-size: $font-size-h1;
		font-weight: 700;
		line-height: 1;
		margin: 0 0 30px 0;
	}

	.percentage-bar {
		margin: 0 0 20px 0;
	}

	.amount-details {
		text-align: right;

		.amount-detail-item {
			font-size: 16px;
			font-weight: 700;
			margin-bottom: 3px;

			.amount {
				font-weight: 400;
				font-size: 18px;
				&.text-bold {
					font-weight: 700;
				}
			}

			&:last-child {
				margin-bottom: 0;
			}
		}
  }

  @media (min-width: $screen-md-min) {
		padding: 30px;
	}
}

.panel-footer {
	border-bottom-right-radius: 6px;
	border-bottom-left-radius: 6px;
	background: #fff;
	a {
		margin: 0 8px;
		text-decoration: none;
		.file-name,
		.footer-link-text {
			text-decoration: underline;
		}
		[class*=icon-] {
			margin-right: 5px;
		}
		&:hover {
			.file-name,
			.footer-link-text {
				text-decoration: none;
			}
		}
	}
}

.panel-default {
	> .panel-heading {
		color: #fff;
		background-color: $gray;
	}
}

.panel-medical,
.panel-pharmacy {
	> .panel-heading {
		background-color: $brand-green;
		color: #fff;
	}
	.member-count {
		color: $brand-green;
	}
}

.panel-dental {
	> .panel-heading {
		background-color: $brand-turquoise;
		color: #fff;
	}
	.member-count {
		color: $brand-turquoise;
	}
}

.panel-heading-simple {
	font-family: 'Roboto-Regular','Avenir Next Condensed',calabri,helvetica,arial,sans-serif;
	font-weight: 700;
	font-size: 20px;
	text-transform: none;
	text-align: left;
	border-radius: 0px;
	padding-bottom: 0px;
}

.panel-body-simple {
	padding-top: 0px;
	font-size: 14px;
}

.panel-toggle {

	.panel-heading {
		position: relative;
		padding: 0;
		text-align: left;
		color: $brand-primary;
	}
	.panel-title {
		font-size: 18px;
		text-transform: none;
		font-weight: 700;

		a {
			padding: 20px 80px 20px 30px;
			display: block;
			text-decoration: none;

			[class*=icon-] {
				position: absolute;
				right: 30px;
				top: 20px;
				font-size: 30px;
				line-height: 20px;
			}

		}

	}
	.panel-body {
		padding: 20px 30px;
	}

	.panel-group & {
		border-radius: 6px;
		margin-bottom: 5px;
	}
}

