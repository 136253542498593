@import "bootstrap/scss/variables";


//== Panels
//
//##
$state-success-text:             #3c763d;
$state-success-bg:               #dff0d8;
$state-success-border:            #dff0d8;


$state-info-text:                #31708f;
$state-info-bg:                  #d9edf7;
$state-info-border:               #31708f;

$state-warning-text:             #8a6d3b;
$state-warning-bg:               #fcf8e3;
$state-warning-border:           #8a6d3b;

$state-danger-text:              #a94442;
$state-danger-bg:                #f2dede;
$state-danger-border:            #a94442;


$panel-bg:                    #fff;
$panel-body-padding:          15px;
$panel-heading-padding:       10px 15px;
$panel-footer-padding:        $panel-heading-padding;
$panel-border-radius:         $border-radius-base;

//** Border color for elements within panels
$panel-inner-border:          #ddd;
$panel-footer-bg:             #f5f5f5;

$panel-default-text:          $gray-dark;
$panel-default-border:        #ddd;
$panel-default-heading-bg:    #f5f5f5;

$panel-primary-text:          #fff;
$panel-primary-border:        $brand-primary;
$panel-primary-heading-bg:    $brand-primary;

$panel-success-text:          $state-success-text;
$panel-success-border:        $state-success-border;
$panel-success-heading-bg:    $state-success-bg;

$panel-info-text:             $state-info-text;
$panel-info-border:           $state-info-border;
$panel-info-heading-bg:       $state-info-bg;

$panel-warning-text:          $state-warning-text;
$panel-warning-border:        $state-warning-border;
$panel-warning-heading-bg:    $state-warning-bg;

$panel-danger-text:           $state-danger-text;
$panel-danger-border:         $state-danger-border;
$panel-danger-heading-bg:     $state-danger-bg;